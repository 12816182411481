.kuika-stripe-payment-button {
  background: #635bff;
  border-radius: 3px;
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  width: 100%;
}

.kuika-stripe-payment-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
